import React from 'react'
import { groupBy } from 'lodash'
import { useStaticQuery,  graphql, Link } from 'gatsby'
import { Flex, Box } from '@rebass/grid/emotion'
import styled from '@emotion/styled-base'
import Slider from "react-slick";

import { Img } from '../../utils/styles'

export const ProductItemContainer = styled(Box)`
  .slick-dots li button:before,
  .slick-dots li.slick-active button:before {
    color: white;
  }

  .slick-dots li {
    margin: 0;
    width: 15px;
  }

  .slick-dots {
    bottom: 5px;
  }

  .slick-slider {
    max-width: 100vw;
    overflow: hidden;
  }
`

const flickityOptions = {
    prevNextButtons: false
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const ProductItem = ({product}) => {

  return (
    <ProductItemContainer
      width={[1, 1 / 2, 1 / 3]}
      mb={5}
      key={product.id}
      style={{
        borderTop: '1px solid #efefef'
      }}
      >
      <Flex px={3} py={3} alignItems="center" justifyContent="space-between">
        <Flex  alignItems="center">
          <Box
            width="30px"
            style={{
              backgroundColor: '#ff7777',
              borderRadius: '50%',
              height: '30px',
              boxShadow: '0 0 0 1px white, 0 0 0 2px rgb(255, 119, 119)'
            }}
            />
          <Link style={{textDecoration: 'none', color: 'black'}} to={`/collections/${product.productType.toLowerCase()}/`}>
            <Box style={{fontWeight: 'bold', textTransform: 'uppercase', fontSize: '12px'}} px={2}>{product.productType}</Box>
          </Link>
        </Flex>
        <Box style={{fontWeight: 'bold', fontSize: '12px'}}>1 of 10</Box>
      </Flex>
      <Link style={{textDecoration: 'none', color: 'black'}} to={`/product/${product.handle}/`}>
        <Slider {...settings}>
          {product.images.map(image => (
            <div>
              <Img
                fluid={image.localFile.childImageSharp.fluid}
                alt={product.handle}
              />
            </div>
          ))}
        </Slider>
        <Flex justifyContent='space-between' alignItems="center" px={3}>
          <Box style={{fontSize: "1.3em", fontFamily: "Abril Fatface, cursive"}}>{product.title}</Box>
          <Box style={{fontWeight: 'bold', fontSize: '14px'}}>${product.variants[0].price}</Box>
        </Flex>
      </Link>
    </ProductItemContainer>
  )
}

export default ProductItem
